import AccountSide from "./AccountSide";
import ContractUpload from "./ContractUpload";
import CompanyEtkinlikCreate from "./CompanyEtkinikCreate";
import EtkinlikListesi from "./EtkinlikListesi";
import {
  BagFill,
  CircleFill,
  FileEarmarkPost,
  PersonCircle,
  Sliders,
  XCircleFill,
  BookFill,
  Radioactive,
} from "react-bootstrap-icons";
import AccountUpdateForm from "./AccountUpdateForm";
import { useEffect, useState } from "react";
import AdressTab from "./AdressTab";
import { useAuth } from "../hooks/useAuth";
import ServiceArea from "./ServiceArea";
export default function AccountLeftSideBar() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const user = useAuth();
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <div className="account-container my-3">
      <div
        className={
          windowSize.innerWidth > 425 ? "d-flex w-100 align-items-start" : ""
        }
      >
        <div
          className="nav flex-column nav-pills me-3"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <button
            className="nav-link active"
            id="v-pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-home"
            type="button"
            role="tab"
            aria-controls="v-pills-home"
            aria-selected="true"
          >
            <PersonCircle style={{ marginRight: 5 }} /> Kişisel Ayarlarım
          </button>
          <button
            className="nav-link"
            id="v-pills-adress-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-adress"
            type="button"
            role="tab"
            aria-controls="v-pills-adress"
            aria-selected="true"
          >
            <BookFill style={{ marginRight: 5 }} /> Adres Bilgilerim
          </button>
          {user.user.isCompany ? (
            <button
              className="nav-link"
              id="v-pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-profile"
              type="button"
              role="tab"
              aria-controls="v-pills-profile"
              aria-selected="false"
            >
              <Sliders style={{ marginRight: 5 }} /> İşletme Ayarlarım
            </button>
          ) : null}

          {user.user.isCompany ? (
            <button
              className="nav-link"
              id="v-pills-messages-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-messages"
              type="button"
              role="tab"
              aria-controls="v-pills-messages"
              aria-selected="false"
            >
              <FileEarmarkPost style={{ marginRight: 5 }} /> İlanlarım
            </button>
          ) : null}
          {user.user.isCompany ? (
            <button
              className="nav-link"
              id="v-pills-area-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-area"
              type="button"
              role="tab"
              aria-controls="v-pills-messages"
              aria-selected="false"
            >
              <Radioactive style={{ marginRight: 5 }} /> Hizmet Verilen Alan
            </button>
          ) : null}
          <button
            className="nav-link"
            id="v-pills-settings-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-settings"
            type="button"
            role="tab"
            aria-controls="v-pills-settings"
            aria-selected="false"
          >
            <BagFill style={{ marginRight: 5 }} /> Satın alımlarım
          </button>
          <button className="nav-link-account-delete">
            <XCircleFill style={{ marginRight: 5 }} /> Hesabımı sil
          </button>
        </div>
        <div className="tab-content w-100" id="v-pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="v-pills-home"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab"
          >
            <AccountSide />
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-adress"
            role="tabpanel"
            aria-labelledby="v-pills-adress-tab"
          >
            <AdressTab />
          </div>
          {user.user.isCompany ? (
            <div
              className="tab-pane fade"
              id="v-pills-profile"
              role="tabpanel"
              aria-labelledby="v-pills-profile-tab"
            >
              <div className="card w-100 ">
                <div
                  className={
                    windowSize.innerWidth > 425
                      ? "card-body d-flex flex-row"
                      : "card-body"
                  }
                  style={
                    windowSize.innerWidth > 425
                      ? { padding: "100px 0" }
                      : { padding: "100px 50px" }
                  }
                >
                  <ContractUpload windowSize={windowSize.innerWidth} />
                  <AccountUpdateForm windowSize={windowSize.innerWidth} />
                </div>
              </div>
            </div>
          ) : null}
          <div
            className="tab-pane fade"
            id="v-pills-messages"
            role="tabpanel"
            aria-labelledby="v-pills-messages-tab"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "60vh",
              }}
            >
              <CompanyEtkinlikCreate />
              <div style={{ width: "100%" }}>
                <EtkinlikListesi />
              </div>
            </div>
          </div>
          {user.user.isCompany ? (
            <div
              className="tab-pane fade"
              id="v-pills-area"
              role="tabpanel"
              aria-labelledby="v-pills-area-tab"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "60vh",
                }}
              >
                <ServiceArea />
              </div>
            </div>
          ) : null}

          <div
            className="tab-pane fade"
            id="v-pills-settings"
            role="tabpanel"
            aria-labelledby="v-pills-settings-tab"
          >
            ...
          </div>
        </div>
      </div>
    </div>
  );
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
