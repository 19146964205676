import { EnvelopeFill, GeoAltFill, TelephoneFill } from "react-bootstrap-icons";
export default function Footer() {
  return (
    <footer className="w-100 py-4 mt-5 flex-shrink-0">
      <div className="container py-4">
        <div className="row gy-4 gx-5">
          <div className="col-lg-4 col-md-6">
            <h5 className="h1 text-white">Show Market</h5>
            <p
              className="small text-muted"
              style={{
                backgroundColor: "transparent",
              }}
            >
              Bir çok etkinliğe ulabileceğiniz organizasyonlara
              katılabileceğiniz show market sizler için burada !
            </p>
            <p
              className="small text-muted mb-0"
              style={{
                backgroundColor: "transparent",
              }}
            >
              &copy; Tüm hakları show market tarafından saklıdır
              <a className="text-primary" href="#">
                showmarket.com
              </a>
            </p>
          </div>
          <div className="col-lg-2 col-md-6">
            <h5 className="text-white mb-3">DOLAŞIM MENÜSÜ</h5>
            <ul className="list-unstyled text-muted">
              <li>
                <a href="/hakkimizda">Hakkımızda</a>
              </li>
              <li>
                <a href="/login">Giriş Yap</a>
              </li>
              <li>
                <a href="/register">Kayıt ol</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-6">
            <h5 className="text-white mb-3">BİLGİ SAYFALARI</h5>
            <ul className="list-unstyled text-muted">
              <li>
                <a
                  href="https://drive.google.com/file/d/1lQ0gQftlX5ZaYQ7BBW6MXIcZOdT1HFRO/view"
                  target="_blank"
                >
                  Kullanıcı sözleşmesi
                </a>
              </li>
              <li>
                <a
                  href="https://drive.google.com/file/d/14rYcDHDr-oXRbWVHu4tu_HrR8Rq1Xgiv/view"
                  target="_blank"
                >
                  KVKK Metni
                </a>
              </li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1YbZC5ds5mvsnQu3SeVy2tnpi8HV-7lUm/view"
                  target="_blank"
                >
                  Mesafeli satış sözleşmesi
                </a>
              </li>
              <li>
                <a
                  href="https://drive.google.com/file/d/18Q6qbC-WFTDMwcXXtJ6dNJQJ-0qfhZCP/view"
                  target="_blank"
                >
                  Çerez Politikası
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-6">
            <h5 className="text-white mb-3">ADRES</h5>
            <ul className="list-unstyled text-muted">
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <GeoAltFill style={{ marginRight: 5 }} size={16} /> Adres :
                Türkiye
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <EnvelopeFill style={{ marginRight: 5 }} size={16} />
                <a href="mailto:showmarket@gmail.com"> showmarket@gmail.com</a>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TelephoneFill style={{ marginRight: 5 }} size={16} />
                <a href="tel:12345678910">Telefon : 1 234 567 89 10</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
