import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Slogan from "../components/Slogan";

export default function CategoryProductLists() {
  const id = useParams();
  const [products, setProducts] = useState([]);
  const [categoryies, setCategories] = useState([]);
  useEffect(() => {
    axios({
      method: "get",
      url: `https://showmarket-backend.herokuapp.com/api/product/get-by-category/${id.id}`,
    })
      .then((res) => {
        // console.log(res.data.data);
        setProducts(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios({
      method: "get",
      url: `https://showmarket-backend.herokuapp.com/api/category/${id.id}`,
    })
      .then((res) => {
        // console.log(res.data.data);
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <Slogan />
      <center>
        <h3 className="body-title-desc mt-3">
          Kullanıcıların En Sevdiği {categoryies.categoryName} <br />{" "}
          Organizasyonları
        </h3>
      </center>
      <div
        className="container"
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {Object.keys(products).length != 0 ? (
          <>
            {products.map((product) => {
              let url = `/eventdetail/${product._id}`;
              return (
                <div className="card" key={product._id}>
                  <div className="card-header">
                    <img src={product.imageUrl} alt="rover" />
                  </div>
                  <div className="card-body">
                    <span className="tag tag-teal">
                      {categoryies.categoryName}
                    </span>
                    <h4>{product.title}</h4>
                    <div className="user" style={{ width: "100%" }}>
                      <div
                        className="user-info"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5 className="body-title-desc">{product.rating}</h5>
                        <div></div>
                        <a
                          href={url}
                          style={{
                            width: 100,
                            height: 50,
                            border: "none",
                            backgroundColor: "#fb3c00",
                            color: "white",
                            borderRadius: 30,
                            outline: "none",
                            textTransform: "uppercase",
                            textDecoration: "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Detay
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                margin: "100px 0",
                width: "50%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h3>Henüz bu kategoride etkinlik yok !</h3>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
