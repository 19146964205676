class CreateProduct {
  setProductData = async (products) => {
    localStorage.setItem("products", JSON.stringify(products));
  };

  getProductsDataValue = () => {
    return JSON.parse(localStorage.getItem("products"));
  };
}

const createProduct = new CreateProduct();
export default createProduct;
