import axios from "axios";
import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useAuth } from "../hooks/useAuth";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import createProduct from "../helpers/CreateProduct";

export default function CompanyEtkinikCreate() {
  const { user } = useAuth();
  const [categories, setCategories] = useState();
  const navigate = useNavigate();
  const [onPressButton, setOnPressButton] = useState(false);
  const [selectValue, setSelectValue] = useState("");

  const buttonStyle = {
    backgroundColor: "#ff4b2b",
    padding: "0.6rem 2.4rem",
    textTransform: "uppercase",
    color: "#fff",
    borderRadius: "2rem",
    fontWeight: "700",
    border: "1px solid #ff4b2b",
    transition: "all 200ms linear",
    marginBottom: 5,
    marginTop: "5%",
  };

  const onButtonStyle = {
    backgroundColor: "#ff4b2b",
    padding: "0.6rem 2.4rem",
    textTransform: "uppercase",
    color: "#fff",
    borderRadius: "2rem",
    fontWeight: "700",
    border: "1px solid #ff4b2b",
    transition: "all 200ms linear",
    marginBottom: 5,
    marginTop: "2%",
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: "https://showmarket-backend.herokuapp.com/api/category/",
    })
      .then(function (response) {
        setCategories(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const EtkinlikSchema = Yup.object().shape({
    title: Yup.string().required("Zorunlu alan"),
    description: Yup.string().required("Zorunlu alan"),
    price: Yup.number().min(1, "Fiyat 0 TL olamaz").required("Zorunlu alan"),
    ilanadi: Yup.string().required("Zorunlu alan"),
  });

  return (
    <div
      style={{
        width: 400,
      }}
    >
      <div className="w-100 d-flex justify-content-center">
        <h2>Etkinlik Oluştur</h2>
      </div>
      <div
        className="accordion"
        id="accordionExample"
        style={{
          width: 400,
        }}
      >
        <div className="accordion-body">
          <Formik
            initialValues={{
              title: "",
              description: "",
              file: "",
              categoryId: "",
              ilanadi: "",
              price: Number,
            }}
            validationSchema={EtkinlikSchema}
            onSubmit={async (values) => {
              let imgPath;
              let isPostSuccess;
              setOnPressButton(true);
              const formData = new FormData();
              for (let value in values) {
                formData.append(value, values[value]);
              }
              await axios
                .post(
                  "https://showmarket-backend.herokuapp.com/upload",
                  formData
                )
                .then((res) => {
                  imgPath = res.data.file;
                })
                .catch((err) => {
                  console.log(err);
                });

              await axios({
                method: "post",
                url: "https://showmarket-backend.herokuapp.com/api/product/",
                headers: {
                  "Content-Type": "application/json",
                },
                data: JSON.stringify({
                  userId: user._id,
                  categoryId:
                    selectValue === ""
                      ? "63d926ca1aca1b9897e90d82"
                      : selectValue,
                  name: values.ilanadi,
                  description: values.description,
                  title: values.title,
                  imageUrl: imgPath,
                  price: values.price,
                }),
              })
                .then(function (response) {
                  isPostSuccess = true;
                })
                .catch(function (error) {
                  console.log(error);
                  isPostSuccess = false;
                });
              if (isPostSuccess) {
                swal({
                  title: "Etkinliğiniz başarılı bir şekilde oluşturuldu",
                  text: "Keyifli vakitler dileriz.",
                  icon: "success",
                  button: "Tamam",
                }).then(function () {
                  navigate(0);
                });
              } else {
                swal({
                  title:
                    "Etkinliğiniz oluşturulurken bir hata ile karşılaşıldı.",
                  text: "Lütfen destek ekibiyle iletişime geçiniz.",
                  icon: "error",
                  button: "Daha sonra tekrar deneyebilirim",
                }).then(function () {
                  navigate(0);
                });
              }
            }}
          >
            {(formik) => (
              <Form encType="multipart/form-data">
                <div className="form-floating">
                  <select
                    className="form-select"
                    id="select"
                    aria-label="Floating label select example"
                    name="categoryId"
                    onChange={(event) => setSelectValue(event.target.value)}
                  >
                    {categories
                      ? categories.map(({ _id, categoryName }) => {
                          return (
                            <option key={_id} value={_id}>
                              {categoryName}
                            </option>
                          );
                        })
                      : null}
                  </select>
                  <label htmlFor="select">Kategori Seç</label>
                </div>
                {formik.errors.categoryId ? (
                  <div>{formik.errors.categoryId}</div>
                ) : null}
                <div class="input-group my-3">
                  <span class="input-group-text">₺</span>
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Amount (to the nearest dollar)"
                    name="price"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    required
                  />
                  <span class="input-group-text">.00</span>
                </div>
                {formik.errors.price ? <div>{formik.errors.price}</div> : null}
                <div className="form-floating mt-3">
                  <input
                    className="form-control"
                    id="x"
                    name="title"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  <label htmlFor="x">Başlık</label>
                </div>
                <div className="form-floating mt-3">
                  <input
                    className="form-control"
                    id="u"
                    name="ilanadi"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  <label htmlFor="u">İlan adı</label>
                </div>
                {formik.errors.ilanadi ? (
                  <div>{formik.errors.ilanadi}</div>
                ) : null}
                <div className="form-floating mt-3">
                  <textarea
                    className="form-control"
                    id="y"
                    name="description"
                    style={{ height: 200 }}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  ></textarea>
                  <label htmlFor="y">Açılama</label>
                </div>
                {formik.errors.description ? (
                  <div>{formik.errors.description}</div>
                ) : null}
                <div className="mt-3">
                  <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    name="file"
                    accept="image/*"
                    onChange={(e) =>
                      formik.setFieldValue("file", e.currentTarget.files[0])
                    }
                    multiple
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <button
                    type="submit"
                    style={onPressButton ? onButtonStyle : buttonStyle}
                    disabled={onPressButton ? "disabled" : null}
                  >
                    {onPressButton
                      ? "Etkinlik ekleniyor lütfen bekleyin..."
                      : "kaydet"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
