import { Outlet } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import { useAuth } from "./hooks/useAuth";

function App() {
  const { user, logout } = useAuth();
  return (
    <>
      <Header />
      <Navbar />
      <Outlet />
      {window.location.href.indexOf("account") == -1 ? <Footer /> : null}
    </>
  );
}

export default App;
