import "../components/assets/login.css";
import LogoShowMarket from "../components/assets/img/logo.png";
import { useAuth } from "../hooks/useAuth";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import LoginWall from "../components/assets/img/loginregister.jpeg";

export default function Login() {
  const { login } = useAuth();
  const [messages, setMessages] = useState();

  const LoginSchema = Yup.object().shape({
    pass: Yup.string().required("Zorunlu alan *"),
    mail: Yup.string().email("Geçersiz format").required("Zorunlu alan *"),
  });

  return (
    <div className="login-container">
      <div className="row container-main-style">
        <div className="login-wrapper col-md-6">
          <div className="heading">
            <img src={LogoShowMarket} alt="" />
            <h1>Giriş Yap</h1>
          </div>
          <a href="/register" className="btn btn-forget">
            Hesabın yok mu şimdi kayıt ol
          </a>
          <Formik
            initialValues={{ mail: "", pass: "" }}
            validationSchema={LoginSchema}
            onSubmit={(dt) => {
              const data = {
                mail: dt.mail,
                pass: dt.pass,
              };

              var config = {
                method: "post",
                url: "https://showmarket-backend.herokuapp.com/api/user/auth",
                headers: {
                  "Content-Type": "application/json",
                },
                data: JSON.stringify(data),
              };

              axios(config)
                .then((res) => {
                  if (res.data.message == "Logined user") {
                    login(res.data.data);
                  } else if (res.data.data == false) {
                    setMessages("Kullanıcı adı veya şifre hatalı");
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          >
            {(formik) => (
              <Form className="form-group">
                <div style={{ marginBottom: 10 }}>
                  {messages ? messages : null}
                </div>
                <Field
                  type="text"
                  placeholder="e-posta"
                  className="email"
                  name="mail"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.mail && formik.touched.mail ? (
                  <div>{formik.errors.mail}</div>
                ) : null}
                <Field
                  type="password"
                  placeholder="şifre"
                  className="passwd"
                  name="pass"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.pass && formik.touched.pass ? (
                  <div>{formik.errors.pass}</div>
                ) : null}
                <button type="submit" className="btn btn-primary">
                  Giriş yap
                </button>
              </Form>
            )}
            {/* <img src={LoginWall} alt="not load" style={{ width: "100%" }} /> */}
          </Formik>
        </div>
        <div className="col-md-6 login-img-wrapper">
          <img src={LoginWall} alt="not load" className="login-img" />
        </div>
      </div>
    </div>
  );
}
