export default function HakkimizdaShowMarket() {
  return (
    <div className="row">
      <div className="col-md-6">
        <br />
        <img
          className="hakkimizdaImg"
          src="https://t4.ftcdn.net/jpg/01/32/20/35/360_F_132203596_FtbqXXuqShxKPs3xvg2gmNP8ygZAnINt.jpg"
          width="100%"
          alt=""
        />
      </div>
      <div className="col-md-6">
        <br />
        <h5
          style={{
            fontWeight: "bold",
          }}
        >
          SHOW MARKET
        </h5>
        <h5 className="body-title-desc">Biz Kimiz ?</h5>
        <p>
          1991 yılından bu yana hizmet verdiğimiz bu sektörde, yaşanan sorun ve
          sıkıntıları birebir gözlemlemekteyiz. Ortaya çıkabilecek her türlü
          sorunun çözümü adına yıllardır çalışmalar yapmaktayız. Show Market ise
          ilk olarak 2018 yılında PDF olarak kaleme alınmış ve bugün mobil
          uygulama olarak hizmete sunduğumuz, yaptığımız çalışmalar neticesinde
          attığımız adımların son projesidir.
        </p>
      </div>
    </div>
  );
}
