import axios from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import swal from "sweetalert";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";

export default function EtkilikListesi() {
  const { user } = useAuth();
  const [etkinlik, setEtkinlik] = useState();
  const [categories, setCategories] = useState();
  const [data, setData] = useState();
  const [getCategoryName, setCategoryName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    axios({
      method: "get",
      url: `https://showmarket-backend.herokuapp.com/api/product/get-by-company/${user._id}`,
    })
      .then(function (response) {
        setEtkinlik(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios({
      method: "GET",
      url: "https://showmarket-backend.herokuapp.com/api/category/",
    })
      .then(function (response) {
        setCategories(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const deleteAdress = async (id) => {
    await axios({
      method: "delete",
      url: `https://showmarket-backend.herokuapp.com/api/product/hard-delete/${id}`,
      headers: {},
    })
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error.response.data);
      });
  };

  const getDataId = async (id) => {
    await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: `https://showmarket-backend.herokuapp.com/api/product/${id}`,
      headers: {},
    })
      .then(function (response) {
        console.log(response.data.data);
        setData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: `https://showmarket-backend.herokuapp.com/api/category/${data.categoryId}`,
      headers: {},
    })
      .then(function (response) {
        console.log(response.data.data);
        setCategoryName(response.data.data.categoryName);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        marginLeft: 30,
      }}
    >
      <table className="table caption-top">
        <thead>
          <tr>
            <th scope="col">İlan resmi</th>
            <th scope="col">İlan Başlığı</th>
            <th scope="col">İlan Adı</th>
            <th scope="col">Düzenle & Sil</th>
          </tr>
        </thead>
        <tbody>
          {etkinlik
            ? etkinlik.map(({ title, imageUrl, _id, description }) => {
                return (
                  <tr key={_id}>
                    <img src={imageUrl} width={200} alt="..." />
                    <td>{title}</td>
                    <td>{description}</td>
                    <td>
                      <a
                        className="btn btn-warning"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                        onClick={() => {
                          getDataId(_id);
                        }}
                      >
                        Düzenle
                      </a>{" "}
                      <a
                        onClick={() => {
                          swal({
                            title: "Adres kaydınzı silmek üzeresiniz!",
                            text: "Silmeden önce adres kaydınızın geri getirilemez olduğunu belirtmek isteriz",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then((willDelete) => {
                            if (willDelete) {
                              deleteAdress(_id);
                              swal(
                                "Adres kaydınız başarılı bir şekilde silindi",
                                {
                                  icon: "success",
                                }
                              ).then(() => {
                                navigate(0);
                              });
                            }
                          });
                        }}
                        className="btn btn-danger"
                      >
                        Sil
                      </a>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>

      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                İlan Güncelleme
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <Formik
                initialValues={{
                  title: "",
                  description: "",
                  file: "",
                  categoryId: "",
                  ilanadi: "",
                  price: "",
                }}
                onSubmit={async (values) => {
                  console.log(values);
                  console.log(data);
                  let imgPath = "";

                  const formData = new FormData();
                  for (let value in values) {
                    formData.append(value, values[value]);
                  }
                  if (values.file != "") {
                    await axios
                      .post(
                        "https://showmarket-backend.herokuapp.com/upload",
                        formData
                      )
                      .then((res) => {
                        imgPath = res.data.file;
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                  console.log(imgPath);
                  var config = {
                    method: "put",
                    maxBodyLength: Infinity,
                    url: `https://showmarket-backend.herokuapp.com/api/product/${data._id}`,
                    headers: {
                      "Content-Type": "application/json",
                    },
                    data: JSON.stringify({
                      title: values.title != "" ? values.title : data.title,
                      description:
                        values.description != ""
                          ? values.description
                          : data.description,
                      imageUrl: imgPath != "" ? imgPath : data.imageUrl,
                      categoryId:
                        values.categoryId != ""
                          ? values.categoryId
                          : data.categoryId,
                      ilanadi:
                        values.ilanadi != "" ? values.ilanadi : data.name,
                      price: values.price != "" ? values.price : data.price,
                    }),
                  };

                  axios(config)
                    .then(function (response) {
                      console.log(response.data.data);
                      swal({
                        title:
                          "Etkinlik bilgileriniz başarılı bir şeklide güncellendi",
                        text: "Keyifli vakitler dileriz.",
                        icon: "success",
                        button: "Tamam",
                      }).then(() => {
                        navigate(0);
                      });
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                }}
              >
                {(formik) => (
                  <Form encType="multipart/form-data">
                    <div class="input-group mb-3">
                      <label class="input-group-text" for="inputGroupSelect01">
                        {getCategoryName ? getCategoryName : ""}
                      </label>
                      <select
                        className="form-select"
                        id="select"
                        aria-label="Floating label select example"
                        name="categoryId"
                        onChange={(event) =>
                          formik.setFieldValue("categoryId", event.target.value)
                        }
                      >
                        {categories
                          ? categories.map(({ _id, categoryName }) => {
                              return (
                                <option key={_id} value={_id}>
                                  {categoryName}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                    <div class="input-group my-3">
                      <span class="input-group-text">₺</span>
                      <input
                        type="text"
                        class="form-control"
                        aria-label="Amount (to the nearest dollar)"
                        name="price"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                      />
                      <span class="input-group-text">
                        {data && data.price ? data.price : ""}.00 ₺
                      </span>
                    </div>
                    <div class="input-group mb-3">
                      <span
                        class="input-group-text"
                        id="inputGroup-sizing-default"
                      >
                        {data && data.title ? data.title : ""}
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        name="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div class="input-group mb-3">
                      <span
                        class="input-group-text"
                        id="inputGroup-sizing-default"
                      >
                        {data && data.name ? data.name : ""}
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        name="ilanadi"
                        value={formik.values.ilanadi}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div class="input-group">
                      <span class="input-group-text">
                        {data && data.description ? data.description : ""}
                      </span>
                      <textarea
                        class="form-control"
                        aria-label="With textarea"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                      ></textarea>
                    </div>
                    <img
                      src={data && data.imageUrl ? data.imageUrl : ""}
                      alt="s"
                      style={{ width: "100%" }}
                      className="py-2"
                    />
                    <div className="mb-3">
                      <input
                        className="form-control"
                        type="file"
                        id="formFile"
                        name="file"
                        accept="image/*"
                        onChange={(e) =>
                          formik.setFieldValue("file", e.currentTarget.files[0])
                        }
                        multiple
                      />
                    </div>
                    <button type="submit" class="btn btn-primary">
                      Güncelle
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Kapat
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
