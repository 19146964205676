import HakkimizdaShowMarket from "../components/HakkimizdaShowMarket";
import HakkimizdaMobileLinks from "../components/HakkimizdaMobileLinks";
import HakkimizdaBlogPage from "../components/HakkimizdaBlogPage";

export default function Hakkimizda() {
  return (
    <>
      <div className="container">
        <HakkimizdaShowMarket />
        <HakkimizdaMobileLinks />
        <HakkimizdaBlogPage />
      </div>
    </>
  );
}
