import {
  EnvelopeFill,
  HouseFill,
  Facebook,
  Instagram,
  Linkedin,
} from "react-bootstrap-icons";

export default function Header() {
  return (
    <header>
      <div className="container header-content">
        <div className="header-side">
          <div
            className="location"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <HouseFill
              size={15}
              style={{
                marginRight: 5,
              }}
            />{" "}
            Türkiye
          </div>
          <div
            className="mail"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <EnvelopeFill size={15} />
            <a
              href="mailto:showmarket@gmail.com"
              className="mail-adress"
              style={{
                marginLeft: 5,
              }}
            >
              eboshowmarket@gmail.com 
            </a>
          </div>
          <div className="header-right-side">
            <div className="social-icons">
              <a
                href="https://facebook.com/eboshowmarkets"
                className="social-media-link"
                target="_blank"
              >
                <Facebook size={20} />
              </a>
            </div>
            <div className="social-icons">
              <a
                href="https://instagram.com/eboshowmarket"
                className="social-media-link"
                target="_blank"
              >
                <Instagram size={20} />
              </a>
            </div>
            <div className="social-icons">
              <a
                href="https://www.linkedin.com/groups/9127157/"
                className="social-media-link"
                target="_blank"
              >
                <Linkedin size={20} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
