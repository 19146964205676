class Coordinates {
  setStorageCoordinate = async (getCordinates) => {
    localStorage.setItem(
      "cordiantes",
      JSON.stringify({
        latitude: getCordinates.latitude,
        longitude: getCordinates.longitude,
      })
    );
  };

  getStorage = () => {
    return JSON.parse(localStorage.getItem("cordiantes"));
  };
}

const coordinates = new Coordinates();
export default coordinates;
