import sample from "./assets/videos/x.mp4";

export default function Slogan() {
  return (
    <div className="slogan-area">
      <video className="videoTag" autoPlay loop muted>
        <source src={sample} type="video/mp4" />
      </video>
      <div id="landing">
        <h1>
          <i id="word"></i>
          <br />
          SHOW MARKET'TE
        </h1>
      </div>
    </div>
  );
}
