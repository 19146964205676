import Slogan from "../components/Slogan";
import Securty from "../components/Securty";
import OrganizationTitle from "../components/OrganizationTitle";
import Cardarea from "../components/Cardarea";
import Organization from "../components/Organization";
import SsArea from "../components/SsArea";
import { useAuth } from "../hooks/useAuth";
import { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import swal from "sweetalert";
import coordinates from "../helpers/Coordinates";
import axios from "axios";

export default function Home() {
  const { user, logout } = useAuth();
  const [trends, setTreds] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [indexValue, setIndexValue] = useState(2);
  const updateCordinate = async () => {
    await navigator.geolocation.getCurrentPosition((position) => {
      coordinates.setStorageCoordinate({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });

    if (
      coordinates.getStorage().latitude !== user.coordinates[0].latitude &&
      coordinates.getStorage().longitude !== user.coordinates[0].longitude
    ) {
      let newCoordinatesArr = [];
      newCoordinatesArr.push(coordinates.getStorage());

      for (let i = 0; i < user.coordinates.length; i++) {
        newCoordinatesArr.push(user.coordinates[i]);
      }

      await axios({
        method: "put",
        url: `https://showmarket-backend.herokuapp.com/api/user/${user._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          coordinates: newCoordinatesArr,
        }),
      })
        .then(function (response) {
          //console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });

      swal({
        title: "Konum değişikliği tespit edildi",
        text: "Fiyatlandırma için gereklidir.",
        icon: "success",
        button: "Tamam",
      }).then(() => {
        logout();
      });
    }
  };

  useEffect(() => {
    updateCordinate();
    axios({
      method: "get",
      url: "https://showmarket-backend.herokuapp.com/api/product/",
    })
      .then(function (res) {
        setTreds(res.data.data);
      })
      .catch(function (err) {
        console.log(err);
      });

    axios({
      method: "get",
      url: "https://showmarket-backend.herokuapp.com/api/category/",
    })
      .then((res) => {
        // console.log(res.data.data);
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios({
      method: "get",
      maxBodyLength: Infinity,
      url: "https://showmarket-backend.herokuapp.com/api/product/",
      headers: {},
    })
      .then(function (response) {
        // console.log(response.data.data);
        setProducts(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {}, []);

  return (
    <>
      <Slogan />
      <div className="container main-container">
        {/* <Securty />
        <OrganizationTitle />
        <br />
        <Cardarea />
        <br />
        <br />
        <Organization /> */}
        {/* <!-- öne çıkanlar start --> */}
        <section>
          <h5 class="sectionTitle text-center my-4">Öne Çıkanlar</h5>
          <div class="oneCikanlar">
            {trends.map((anObjectMap) => {
              if (anObjectMap.isTrend) {
                let link = `/eventdetail/${anObjectMap._id}`;
                return (
                  <div class="new_card" style={{ width: "18rem" }}>
                    <a
                      href={link}
                      style={{
                        textDecoration: "none",
                        display: "inline-block",
                      }}
                    >
                      <img
                        src={anObjectMap.imageUrl}
                        class="card-img-top"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5
                          class="card-title"
                          style={{ textDecoration: "none" }}
                        >
                          {anObjectMap.name}
                        </h5>
                        <p style={{ color: "#000", fontSize: "1rem" }}>
                          {anObjectMap.description}
                        </p>
                        <a
                          href={link}
                          style={{
                            background: "#ff4b2b",
                            padding: ".375rem .75rem",
                            textTransform: "lowercase",
                            color: "#fff",
                            borderRadius: "0.25rem",
                            fontWeight: "400",
                            border: "1px solid #ff4b2b",
                            transition: "all 200ms linear",
                            marginBottom: "5px",
                            textDecoration: "none",
                            fontSize: "1rem",
                          }}
                        >
                          İncele
                        </a>
                      </div>
                    </a>
                  </div>
                );
              }
            })}
          </div>
        </section>
        {/* <!-- öne çıkanlar end --> */}
        <div className="card-area">
          {categories.map((anObjectMap, index) => {
            const url = `/categorylist/${anObjectMap._id}`;
            if (index <= indexValue) {
              return (
                <a href={url} className="show-category">
                  <div
                    style={{
                      backgroundImage: `url("https://images.unsplash.com/photo-1431794062232-2a99a5431c6c?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ")`,
                      width: 300,
                      height: 450,
                      backgroundSize: "cover",
                      margin: "10px",
                      display: "flex",
                      padding: 10,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                    }}
                  >
                    <h1 className="text-right">{anObjectMap.categoryName}</h1>
                  </div>
                </a>
              );
            }
          })}
        </div>
        <center>
          <div className="buttons">
            <button
              className="blob-btn"
              onClick={() => {
                if (indexValue == 2) {
                  setIndexValue(Object.keys(categories).length);
                } else {
                  setIndexValue(2);
                }
              }}
            >
              {indexValue == 2 ? "Daha fazla göster" : "Daha az göster"}
              <span className="blob-btn__inner">
                <span className="blob-btn__blobs">
                  <span className="blob-btn__blob"></span>
                  <span className="blob-btn__blob"></span>
                  <span className="blob-btn__blob"></span>
                  <span className="blob-btn__blob"></span>
                </span>
              </span>
            </button>
          </div>
          <br />
        </center>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="goo">
              <feGaussianBlur
                in="SourceGraphic"
                result="blur"
                stdDeviation="10"
              ></feGaussianBlur>
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                result="goo"
              ></feColorMatrix>
              <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
            </filter>
          </defs>
        </svg>
        <section style={{ marginTop: "-10%" }}>
          <h5 class="sectionTitle text-center">Puanlaması en iyiler</h5>
          <br />
          <div class="oneCikanlar">
            {products.map((values) => {
              console.log(values.rating);
              if (values.rating > 4) {
                let link = `/eventdetail/${values._id}`;
                return (
                  <div class="card" style={{ width: "18rem" }}>
                    <a
                      href={link}
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <img
                        src="https://www.timeoutdubai.com/cloud/timeoutdubai/2021/09/11/udHvbKwV-IMG-Dubai-UAE-1.jpg"
                        class="card-img-top"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title">{values.name}</h5>
                        <p style={{ fontSize: "1rem" }}>{values.description}</p>
                        <a
                          href={link}
                          style={{
                            background: "#ff4b2b",
                            padding: ".375rem .75rem",
                            textTransform: "lowercase",
                            color: "#fff",
                            borderRadius: "0.25rem",
                            fontWeight: "400",
                            border: "1px solid #ff4b2b",
                            transition: "all 200ms linear",
                            marginBottom: "5px",
                            textDecoration: "none",
                            fontSize: "1rem",
                          }}
                        >
                          İncele
                        </a>
                      </div>
                    </a>
                  </div>
                );
              } else {
                return (
                  <div className="d-flex justify-content-center w-100">
                    <div
                      class="alert alert-warning w-50 text-center"
                      role="alert"
                    >
                      Puanlaması en iyi olan ürün bulunmamaktadır!
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </section>
        <CookieConsent
          location="bottom"
          buttonText="Kabul Et"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          İnternet sitemizin çalışması, analitik çalışmaların yürütülmesi ve
          tercihleriniz doğrultusunda sitenin kişiselleştirilmesi için çerezler
          kullanmaktayız. Ayrıca açık rıza vermeniz halinde size yönelik
          reklam/pazarlama faaliyetlerinin gerçekleştirilmesi kapsamında birinci
          ve üçüncü taraf çerezler kullanılacaktır. Çerezlere dair
          tercihlerinizi panelden yönetebilir veya detaylı bilgi için Çerez
          Aydınlatma Metni’ni inceleyebilirsiniz.
        </CookieConsent>
      </div>
      {/* <SsArea /> */}
    </>
  );
}
