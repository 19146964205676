import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Errorpage from "./pages/Errorpage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Hakkimizda from "./pages/Hakkimizda";
import Blog from "./pages/Blog";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import EventDetail from "./pages/EventDetail";
import { AuthProvider } from "./hooks/useAuth";
import CategoryProductLists from "./pages/CategoryProductLists";
import Account from "./pages/Account";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<Home />} />
          <Route path="/hakkimizda" element={<Hakkimizda />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/categorylist/:id" element={<CategoryProductLists />} />
          <Route path="/eventdetail/:id" element={<EventDetail />} />
          <Route path="/account" element={<Account />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </AuthProvider>
  </BrowserRouter>
);
