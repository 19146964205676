import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { PencilSquare, X } from "react-bootstrap-icons";
import { useAuth } from "../hooks/useAuth";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
export default function AccountUpdateForm(props) {
  const [company, setComapny] = useState(false);
  const [taxNum, setTaxNum] = useState(false);
  const [companyRank, setCompanyRank] = useState(false);
  const [getUserData, setUserData] = useState([]);
  const user = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    axios({
      method: "get",
      url: `https://showmarket-backend.herokuapp.com/api/user/get/${user.user._id}`,
      headers: {},
    })
      .then(function (response) {
        //console.log(response.data.data);
        setUserData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div style={props.windowSize > 425 ? { marginLeft: 50 } : null}>
      <Formik
        initialValues={{
          company: "",
          taxNum: "",
          companyRank: "",
        }}
        onSubmit={(values) => {
          console.log(values);
          if (values.company.length != 0 || values.taxNum.length != 0) {
            axios({
              method: "put",
              url: `https://showmarket-backend.herokuapp.com/api/user/${user.user._id}`,
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify({
                company:
                  values.company.length == 0
                    ? getUserData.company
                    : values.company,
                companyRank:
                  values.companyRank.length == 0
                    ? getUserData.companyRank
                    : values.companyRank,
                taxNum:
                  values.taxNum.length == 0
                    ? getUserData.taxNum
                    : values.taxNum,
              }),
            })
              .then(function (response) {
                console.log(JSON.stringify(response.data));
                swal({
                  title: "Bilgileriniz başarılı bir şeklide güncellendi",
                  text: "Keyifli vakitler dileriz.",
                  icon: "success",
                  button: "Tamam",
                }).then(() => {
                  navigate(0);
                });
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            swal({
              title:
                "Şirket bilgilerinizi güncellerken boş değer girmemelisiniz",
              icon: "error",
              button: "Tamam",
            });
          }
        }}
      >
        {(formik) => (
          <Form
            className="form-group"
            style={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            {!company ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <a
                  onClick={() => {
                    setComapny(true);
                  }}
                  style={{
                    backgroundColor: "#ff4b2b",
                    padding: 10,
                    color: "#fff",
                    borderRadius: 5,
                    textTransform: "uppercase",
                    cursor: "pointer",
                    margin: "10px 5px",
                  }}
                >
                  <PencilSquare fontSize={20} />
                </a>
                <label
                  htmlFor=""
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    marginRight: 20,
                    color: "#ff4b2b",
                  }}
                >
                  {getUserData.company == ""
                    ? "Bilgi bulunamadı"
                    : getUserData.company}
                </label>
              </div>
            ) : (
              <div
                className="form-group"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  placeholder="İş yeri ünvanı"
                  className="isYeriUnvani"
                  name="company"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                <a
                  onClick={() => setComapny(false)}
                  style={{
                    marginBottom: 12,
                    backgroundColor: "#ff4b2b",
                    padding: 10,
                    color: "#fff",
                    borderRadius: 5,
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                >
                  <X fontSize={20} />
                </a>
              </div>
            )}
            {!taxNum ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <a
                  onClick={() => {
                    setTaxNum(true);
                  }}
                  style={{
                    backgroundColor: "#ff4b2b",
                    padding: 10,
                    color: "#fff",
                    borderRadius: 5,
                    textTransform: "uppercase",
                    cursor: "pointer",
                    margin: "10px 5px",
                  }}
                >
                  <PencilSquare fontSize={20} />
                </a>
                <label
                  htmlFor=""
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "#ff4b2b",
                  }}
                >
                  {getUserData.taxNum == ""
                    ? "Bilgi bulunamadı"
                    : getUserData.taxNum}
                </label>
              </div>
            ) : (
              <div
                className="form-group"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  placeholder="Vergi Numarası"
                  className="vergiNo"
                  name="taxNum"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                <a
                  onClick={() => setTaxNum(false)}
                  style={{
                    marginBottom: 12,
                    backgroundColor: "#ff4b2b",
                    padding: 10,
                    color: "#fff",
                    borderRadius: 5,
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                >
                  <X fontSize={20} />
                </a>
              </div>
            )}
            {!companyRank ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <a
                  onClick={() => {
                    setCompanyRank(true);
                  }}
                  style={{
                    backgroundColor: "#ff4b2b",
                    padding: 10,
                    color: "#fff",
                    borderRadius: 5,
                    textTransform: "uppercase",
                    cursor: "pointer",
                    margin: "10px 5px",
                  }}
                >
                  <PencilSquare fontSize={20} />
                </a>
                <label
                  htmlFor=""
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "#ff4b2b",
                  }}
                >
                  {getUserData.companyRank == ""
                    ? "Bilgi bulunamadı"
                    : getUserData.companyRank}
                </label>
              </div>
            ) : (
              <div
                className="form-group"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <select
                  name="companyRank"
                  className="company-type"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                >
                  <option value="Şahıs Şirketi">Şahıs Şirketi</option>
                  <option value="Anonim Şirket">Anonim Şirket</option>
                  <option value="Limited Şirket">Limited Şirket</option>
                  <option value="Kollektif Şirket">Kollektif Şirket</option>
                  <option value="Komandit Şirket">Komandit Şirket</option>
                  <option value="Kooperatif Şirket">Kooperatif Şirket</option>
                </select>
                <a
                  onClick={() => setCompanyRank(false)}
                  style={{
                    marginBottom: 12,
                    backgroundColor: "#ff4b2b",
                    padding: 10,
                    color: "#fff",
                    borderRadius: 5,
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                >
                  <X fontSize={20} />
                </a>
              </div>
            )}
            <button type="submit" className="btn btn-primary mt-3">
              Güncelle
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
