export default function BlogCard() {
  return (
    <div
      className="blog-card"
      style={{
        width: "18rem",
      }}
    >
      <img
        src="https://www.trtmuzik.net.tr/public/dm_upload/modul13/ecc4169a-39c4-4e7d-bdb2-8cb94d27e7b9.png"
        className="card-img-top"
        alt="..."
      />
      <div className="card-body">
        <h5 className="card-title">Card title</h5>
        <p className="card-text">
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </p>
        <a href="#" className="btn readMore">
          Devamını oku
        </a>
      </div>
    </div>
  );
}
