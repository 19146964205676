class DrawCoordinate {
  setDrawStorageCoordinate = async (getCordinates) => {
    localStorage.setItem(
      "drawCoordinates",
      JSON.stringify({
        latitude: getCordinates.data.lat,
        longitude: getCordinates.data.lng,
        range: getCordinates.radius,
      })
    );
  };

  getDrawStorage = () => {
    return JSON.parse(localStorage.getItem("drawCoordinates"));
  };
}

const drawCoordinates = new DrawCoordinate();
export default drawCoordinates;
