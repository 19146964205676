import axios from "axios";
import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import * as Yup from "yup";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import WageCalculation from "../helpers/WageCalculation";

export default function EventDetail() {
  const [data, setData] = useState([]);
  const [questions, setQuestion] = useState([]);
  const [comments, setComments] = useState([]);
  const [getCategoryName, setCategoryName] = useState();
  const { user } = useAuth();
  const id = useParams();
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [serviceRange, setServiceRange] = useState();
  const [wageCalc, setWageCalc] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    getAllDatas();
  }, []);

  const getAllDatas = async () => {
    let categoryIdValue;
    let getAllProductData;
    let getCategoryNameData;
    let localServiceRange;
    let userID;
    setLoading(true);
    await axios({
      method: "get",
      url: "https://showmarket-backend.herokuapp.com/api/demands/",
    })
      .then((res) => {
        setQuestion(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    await axios({
      method: "get",
      url: `https://showmarket-backend.herokuapp.com/api/product/${id.id}`,
    })
      .then((res) => {
        getAllProductData = res.data.data;
        categoryIdValue = res.data.data.categoryId;
        userID = res.data.data.userId;
      })
      .catch((err) => {
        console.log(err);
      });

    await axios({
      method: "get",
      url: `https://showmarket-backend.herokuapp.com/api/user/get/${userID}`,
    })
      .then((res) => {
        setCompanyName(res.data.data.company);
        setServiceRange(
          setWageCalc(
            WageCalculation.calcCrow(
              res.data.data.servicesRange[0].latitude,
              res.data.data.servicesRange[0].longitude,
              user.coordinates[0].latitude,
              user.coordinates[0].longitude
            )
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });

    await axios({
      method: "get",
      url: `https://showmarket-backend.herokuapp.com/api/category/${categoryIdValue}`,
    })
      .then((res) => {
        getCategoryNameData = res.data.data.categoryName;
      })
      .catch((err) => {
        console.log(err);
      });

    await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: `https://showmarket-backend.herokuapp.com/api/comments/get-by-productId/${id.id}`,
      headers: {},
    })
      .then(function (response) {
        console.log(response.data.data);
        setComments(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    setData(getAllProductData);
    setCategoryName(getCategoryNameData);

    setLoading(false);
  };

  return (
    <div className="container">
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="spinner-border text-warning"
            role="status"
            style={{
              width: "4rem",
              height: "4rem",
              margin: "100px 0",
            }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="row">
          <div
            className="col-md-4"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {getCategoryName ? (
              <span
                className="tag tag-teal"
                style={{
                  padding: "10px 20px",
                  fontSize: 14,
                  margin: "20px 0",
                }}
              >
                {getCategoryName}
              </span>
            ) : null}

            <img
              src={data.imageUrl}
              alt="img"
              style={{
                width: "100%",
              }}
            />

            {companyName.length != 0 ? (
              <span
                className="tag tag-teal"
                style={{
                  padding: "10px 20px",
                  fontSize: 14,
                  margin: "20px 0",
                }}
              >
                {companyName}
              </span>
            ) : null}

            <div
              style={{
                width: "80%",
                height: 50,
                backgroundColor: "#fb3c00",
                margin: "30px 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 20,
                color: "white",
                fontWeight: 100,
                fontSize: 20,
                textTransform: "uppercase",
              }}
            >
              {data.name}
            </div>

            <div
              className="accordion-right-style"
              style={{
                width: "100%",
              }}
            >
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      style={{
                        textTransform: "uppercase",
                      }}
                    >
                      Soru sor ?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {user ? (
                        <Formik
                          initialValues={{
                            comment: "",
                          }}
                          validationSchema={Yup.object().shape({
                            comment: Yup.string().required("Zorunlu alan *"),
                          })}
                          onSubmit={async (values) => {
                            let isUser = false;
                            let questionsNewArr = [];
                            let questionId;

                            questions.map((element) => {
                              if (element.userId == user._id) {
                                isUser = true;
                                questionsNewArr = element.questions;
                                questionId = element._id;
                              }
                            });

                            questionsNewArr.push(values.comment);

                            if (isUser) {
                              await axios({
                                method: "put",
                                url: `https://showmarket-backend.herokuapp.com/api/demands/${questionId}`,
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                data: {
                                  questions: questionsNewArr,
                                },
                              });
                              swal({
                                title: "Sorunuz başarılı bir şekide gönderildi",
                                icon: "success",
                                button: "Tamam",
                              }).then(() => {
                                navigate(0);
                              });
                            } else {
                              if (user._id != data.userId) {
                                await axios({
                                  method: "post",
                                  url: "https://showmarket-backend.herokuapp.com/api/demands/",
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                  data: JSON.stringify({
                                    userId: user._id,
                                    companyId: data.userId,
                                    productId: id.id,
                                    questions: [values.comment],
                                  }),
                                })
                                  .then((res) => {
                                    console.log(res);
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                                swal({
                                  title:
                                    "İlk sorunuz Sorunuz başarılı bir şekide gönderildi",
                                  icon: "success",
                                  button: "Tamam",
                                }).then(() => {
                                  navigate(0);
                                });
                              } else {
                                swal({
                                  title:
                                    "Kendi etkinliğinize soru soramazsınız!",
                                  icon: "error",
                                  button: "Tamam",
                                });
                              }
                            }
                          }}
                        >
                          {(formik) => (
                            <Form>
                              {formik.errors.comment ? (
                                <div style={{ color: "red" }}>
                                  {formik.errors.comment}
                                </div>
                              ) : null}
                              <div className="form-floating">
                                <textarea
                                  className="form-control"
                                  placeholder="Leave a comment here"
                                  id="floatingTextarea2"
                                  name="comment"
                                  value={formik.values.name}
                                  onChange={formik.handleChange}
                                  style={{
                                    height: "200px",
                                  }}
                                ></textarea>
                                <label for="floatingTextarea2">Sorunuz</label>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-primary mt-4"
                                  style={{
                                    marginRight: 20,
                                  }}
                                >
                                  Gönder
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      ) : (
                        <>
                          <div>
                            <h4>Soru sormak için lütfen giriş yapın</h4>
                            <br />
                            <a href="/login" className="btn btn-primary">
                              Giriş yap
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="accordion-right-style"
              style={{
                width: "100%",
              }}
            >
              <div className="accordion" id="accordionExamplex">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOnex">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOnex"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      style={{
                        textTransform: "uppercase",
                      }}
                    >
                      Yorum yap
                    </button>
                  </h2>
                  <div
                    id="collapseOnex"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOnex"
                    data-bs-parent="#accordionExamplex"
                  >
                    <div className="accordion-body">
                      {user ? (
                        <Formik
                          initialValues={{
                            title: "",
                            text: "",
                            rank: "",
                          }}
                          validationSchema={Yup.object().shape({
                            title: Yup.string().required("Zorunlu alan *"),
                            text: Yup.string().required("Zorunlu alan *"),
                          })}
                          onSubmit={async (values) => {
                            await axios({
                              method: "post",
                              maxBodyLength: Infinity,
                              url: "https://showmarket-backend.herokuapp.com/api/comments/",
                              headers: {
                                "Content-Type": "application/json",
                              },
                              data: JSON.stringify({
                                productId: id.id,
                                userId: user._id,
                                title: values.title,
                                text: values.text,
                                rating: values.rank === "" ? "1" : values.rank,
                              }),
                            })
                              .then(function (response) {
                                swal({
                                  title:
                                    "Yorumunuzu başarılı bir şekilde oluşturuldu.",
                                  text: "Keyifli vakitler dileriz.",
                                  icon: "success",
                                  button: "Tamam",
                                }).then(() => {
                                  navigate(0);
                                });
                              })
                              .catch(function (error) {
                                console.log(error);
                                swal({
                                  title:
                                    "Yorumunuz oluşturulamadı lütfen teknik ekiple iletişime geçiniz.",
                                  text: "Keyifli vakitler dileriz.",
                                  icon: "error",
                                  button: "Tamam",
                                }).then(() => {
                                  navigate(0);
                                });
                              });
                          }}
                        >
                          {(formik) => (
                            <Form>
                              <div class="form-floating">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="floatingText"
                                  placeholder="Password"
                                  name="title"
                                  value={formik.values.title}
                                  onChange={formik.handleChange}
                                />
                                <label for="floatingText">Konu Başlığı</label>
                              </div>
                              {formik.errors.title ? (
                                <>{formik.errors.title}</>
                              ) : null}
                              <div class="form-floating mt-2">
                                <textarea
                                  class="form-control"
                                  placeholder="Leave a comment here"
                                  id="floatingTextarea2"
                                  style={{ height: "100px" }}
                                  name="text"
                                  value={formik.values.text}
                                  onChange={formik.handleChange}
                                ></textarea>
                                <label for="floatingTextarea2">Yorum</label>
                              </div>
                              {formik.errors.text ? (
                                <>{formik.errors.text}</>
                              ) : null}
                              <div class="form-floating mt-2">
                                <select
                                  class="form-select"
                                  id="floatingSelect"
                                  aria-label="Floating label select example"
                                  name="rank"
                                  value={formik.values.rank}
                                  onChange={formik.handleChange}
                                >
                                  <option value="1" selected>
                                    1
                                  </option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                </select>
                                <label for="floatingSelect">
                                  Ürünü değerlendirin 1-5
                                </label>
                              </div>
                              <button
                                type="submit"
                                className="btn btn-primary mt-2"
                              >
                                Yorum Yap
                              </button>
                            </Form>
                          )}
                        </Formik>
                      ) : (
                        <>
                          <div>
                            <h4>Soru sormak için lütfen giriş yapın</h4>
                            <br />
                            <a href="/login" className="btn btn-primary">
                              Giriş yap
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-6"
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0 40px",
            }}
          >
            <h1
              style={{
                textAlign: "center",
                margin: "3%",
                color: "#fb3c00",
                textTransform: "uppercase",
              }}
            >
              {data.title}
            </h1>
            <p>
              {data.description}
              {/* Lorem, ipsum dolor sit amet consectetur
            adipisicing elit. Nisi, quam. Recusandae delectus culpa ipsum
            excepturi nulla et veniam dicta, explicabo commodi repudiandae quas.
            Enim, ipsum blanditiis! Molestias placeat error iusto. Lorem ipsum
            dolor sit amet consectetur adipisicing elit. Neque, nam dolorum? Ea
            itaque in, recusandae doloribus consequatur repudiandae optio
            laborum laudantium odit ipsa beatae minus eum iusto veniam soluta.
            Architecto. Lorem, ipsum dolor sit amet consectetur adipisicing
            elit. Nisi, quam. Recusandae delectus culpa ipsum excepturi nulla et
            veniam dicta, explicabo commodi repudiandae quas. Enim, ipsum
            blanditiis! Molestias placeat error iusto. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Neque, nam dolorum? Ea itaque in,
            recusandae doloribus consequatur repudiandae optio laborum
            laudantium odit ipsa beatae minus eum iusto veniam soluta.
            Architecto. Lorem, ipsum dolor sit amet consectetur adipisicing
            elit. Nisi, quam. Recusandae delectus culpa ipsum excepturi nulla et
            veniam dicta, explicabo commodi repudiandae quas. Enim, ipsum
            blanditiis! Molestias placeat error iusto. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Neque, nam dolorum? Ea itaque in,
            recusandae doloribus consequatur repudiandae optio laborum
            laudantium odit ipsa beatae minus eum iusto veniam soluta.
            Architecto. Lorem, ipsum dolor sit amet consectetur adipisicing
            elit. Nisi, quam. Recusandae delectus culpa ipsum excepturi nulla et
            veniam dicta, explicabo commodi repudiandae quas. Enim, ipsum
            blanditiis! Molestias placeat error iusto. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Neque, nam dolorum? Ea itaque in,
            recusandae doloribus consequatur repudiandae optio laborum
            laudantium odit ipsa beatae minus eum iusto veniam soluta.
            Architecto. Lorem, ipsum dolor sit amet consectetur adipisicing
            elit. Nisi, quam. Recusandae delectus culpa ipsum excepturi nulla et
            veniam dicta, explicabo commodi repudiandae quas. Enim, ipsum
            blanditiis! Molestias placeat error iusto. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Neque, nam dolorum? Ea itaque in,
            recusandae doloribus consequatur repudiandae optio laborum
            laudantium odit ipsa beatae minus eum iusto veniam soluta.
            Architecto. Lorem, ipsum dolor sit amet consectetur adipisicing
            elit. Nisi, quam. Recusandae delectus culpa ipsum excepturi nulla et
            veniam dicta, explicabo commodi repudiandae quas. Enim, ipsum
            blanditiis! Molestias placeat error iusto. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Neque, nam dolorum? Ea itaque in,
            recusandae doloribus consequatur repudiandae optio laborum
            laudantium odit ipsa beatae minus eum iusto veniam soluta.
            Architecto. Lorem, ipsum dolor sit amet consectetur adipisicing
            elit. Nisi, quam. Recusandae delectus culpa ipsum excepturi nulla et
            veniam dicta, explicabo commodi repudiandae quas. Enim, ipsum
            blanditiis! Molestias placeat error iusto. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Neque, nam dolorum? Ea itaque in,
            recusandae doloribus consequatur repudiandae optio laborum
            laudantium odit ipsa beatae minus eum iusto veniam soluta.
            Architecto. Lorem, ipsum dolor sit amet consectetur adipisicing
            elit. Nisi, quam. Recusandae delectus culpa ipsum excepturi nulla et
            veniam dicta, explicabo commodi repudiandae quas. Enim, ipsum
            blanditiis! Molestias placeat error iusto. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Neque, nam dolorum? Ea itaque in,
            recusandae doloribus consequatur repudiandae optio laborum
            laudantium odit ipsa beatae minus eum iusto veniam soluta.
            Architecto. Lorem, ipsum dolor sit amet consectetur adipisicing
            elit. Nisi, quam. Recusandae delectus culpa ipsum excepturi nulla et
            veniam dicta, explicabo commodi repudiandae quas. Enim, ipsum
            blanditiis! Molestias placeat error iusto. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Neque, nam dolorum? Ea itaque in,
            recusandae doloribus consequatur repudiandae optio laborum
            laudantium odit ipsa beatae minus eum iusto veniam soluta.
            Architecto.  */}
            </p>
          </div>
          <div
            className="col-md-2"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <button
              style={{
                backgroundColor: "#fb3c00",
                outline: "none",
                border: "none",
                color: "white",
                width: "100%",
                height: "50px",
                borderRadius: 10,
                cursor: "pointer",
                fontSize: 20,
                textTransform: "uppercase",
                fontWeight: 300,
              }}
            >
              <strong>
                {data.price === 0
                  ? "ÜCRETSİZ"
                  : WageCalculation.PriceCalc(data.price, wageCalc) +
                    "₺ Satın al"}
              </strong>
            </button>
            <div
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#fb3c00",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                borderRadius: 7,
                marginTop: 20,
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              Ortalama: {data.rating}
            </div>
          </div>
          <div className="my-2 w-100 d-flex justify-content-center">
            <h1>Yorumlar</h1>
          </div>
          <div className="w-100 d-flex justify-content-center mt-4">
            <div class="card w-75">
              <div class="py-4 px-4">
                {comments.map((values) => {
                  return (
                    <div className="my-2">
                      <h2>{values.title}</h2>
                      <p
                        className="py-1"
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {values.text}
                      </p>
                      <p style={{ fontSize: 18, fontWeight: "bold" }}>
                        Puan: {values.rating}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
