import Logo from "./assets/img/logo.png";
import { useAuth } from "../hooks/useAuth";
import { Link } from "react-router-dom";

import {
  PersonCircle,
  BoxArrowRight,
  Headset,
  Pencil,
  HouseCheckFill,
  PeopleFill,
  BoxArrowInRight,
  PersonFill,
  DoorOpenFill,
} from "react-bootstrap-icons";
import { useEffect, useState } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

export default function Navbar() {
  const { user, logout } = useAuth();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [value, setValue] = useState(0);
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "tr",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return windowSize.innerWidth > 425 ? (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div
        className="custom-navbar-container"
        style={{
          padding: "10px 0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <a className="navbar-brand" href="/">
          <img
            src={Logo}
            style={{
              width: 60,
              height: 60,
              marginBottom: -13,
            }}
          />
        </a>
        <span className="logo-slogan">Gösteri dünyasına özgür erişim</span>
        <div id="google_translate_element"></div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {user ? null : (
              <>
                <li className="nav-item">
                  <a
                    className="nav-link custom-link"
                    aria-current="page"
                    href="/"
                  >
                    Anasayfa
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link custom-link" href="/hakkimizda">
                    Hakkımızda
                  </a>
                </li>
                {/* <li className="nav-item">
                <a className="nav-link" href="/blog">
                  Blog
                </a>
              </li> */}
              </>
            )}
            {user ? (
              <li className="nav-item dropdown ">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarScrollingDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PersonCircle style={{ marginRight: 5 }} />
                  {user.mail}
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarScrollingDropdown"
                >
                  <li>
                    <a className="dropdown-item custom-link" href="/account">
                      <Pencil /> Hesabım
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item custom-link" onClick={logout}>
                      <BoxArrowRight /> Çıkış yap
                    </a>
                  </li>
                </ul>
              </li>
            ) : (
              <li className="nav-item dropdown ">
                <a
                  className="nav-link dropdown-toggle custom-link"
                  href="#"
                  id="navbarScrollingDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Üyelik
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarScrollingDropdown"
                >
                  <li>
                    <a className="dropdown-item custom-link" href="/login">
                      Giriş Yap
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item custom-link" href="/register">
                      Kayıt Ol
                    </a>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  ) : (
    <BottomNavigation
      showLabels
      style={{
        position: "fixed",
        bottom: 0,
        zIndex: 100,
        width: "100%",
        height: "10%",
        backgroundColor: "#9b2400",
        borderTopRightRadius: 25,
        borderTopLeftRadius: 25,
      }}
    >
      <BottomNavigationAction
        component={Link}
        to="/"
        label="Anasayfa"
        icon={<HouseCheckFill size={25} />}
        style={{ color: "white" }}
      />
      <BottomNavigationAction
        component={Link}
        to="/hakkimizda"
        label="Hakımızda"
        icon={<PeopleFill size={25} />}
        style={{ color: "white" }}
      />
      {user ? (
        <BottomNavigationAction
          component={Link}
          to="/account"
          label={user.fullName}
          icon={<PersonFill size={25} />}
          style={{ color: "white" }}
        />
      ) : (
        <BottomNavigationAction
          component={Link}
          to="/login"
          label="Giriş Yap"
          icon={<DoorOpenFill size={25} />}
          style={{ color: "white" }}
        />
      )}
      {user ? (
        <BottomNavigationAction
          component={Link}
          onClick={logout}
          label="Çıkış Yap"
          icon={<BoxArrowRight size={25} />}
          style={{ color: "white" }}
        />
      ) : null}
    </BottomNavigation>
  );
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
