import { useAuth } from "../hooks/useAuth";
import { Formik, Form } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useEffect, useState } from "react";

import {
  PencilSquare,
  X,
  EyeFill,
  EyeSlashFill,
  PlusCircleFill,
} from "react-bootstrap-icons";

export default function AccountSide() {
  const user = useAuth();
  const navigate = useNavigate();
  const [file, setFile] = useState();

  const [isEye, setEye] = useState(false);
  const [isLoad, setLoad] = useState(false);

  const [userData, setUserData] = useState([]);

  const [fullName, setFullName] = useState(false);
  const [mail, setMail] = useState(false);
  const [phone, setPhone] = useState(false);
  const [password, setPassword] = useState(false);

  if (!user) {
    navigate("/");
  }

  useEffect(() => {
    alldata();
  }, []);

  async function alldata() {
    setLoad(true);
    await axios({
      method: "get",
      url: `https://showmarket-backend.herokuapp.com/api/user/get/${user.user._id}`,
      headers: {},
    })
      .then(function (response) {
        //console.log(response.data.data);
        setUserData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    setLoad(false);
  }

  return (
    <div className="container bodyContent">
      <div className="row">
        <div className="col-sm-10">
          <div className="account-content">
            <div className="container">
              <div className="row gutters">
                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                  <div className="account-card h-100">
                    <div className="account-card-body">
                      <div className="account-settings">
                        <div className="about">
                          <h5>Hakkımda</h5>
                          <p>{userData.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                  <div className="account-card h-100">
                    <div className="card-body">
                      <Formik
                        initialValues={{
                          fullName: "",
                          mail: "",
                          phone: "",
                          password: "",
                          file: "",
                        }}
                        onSubmit={async (values) => {
                          let filePath;

                          const formData = new FormData();
                          for (let value in values) {
                            formData.append(value, values[value]);
                          }
                          if (values.file != "") {
                            await axios
                              .post(
                                "https://showmarket-backend.herokuapp.com/upload",
                                formData
                              )
                              .then((res) => {
                                console.log(res.data.file);
                                if (res.data.file) {
                                  filePath = res.data.file;
                                }
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          }
                          setLoad(true);
                          await axios({
                            method: "put",
                            url: `https://showmarket-backend.herokuapp.com/api/user/${user.user._id}`,
                            headers: {
                              "Content-Type": "application/json",
                            },
                            data: JSON.stringify({
                              fullName:
                                values.fullName.length == 0
                                  ? userData.fullName
                                  : values.fullName,
                              mail:
                                values.mail.length == 0
                                  ? userData.mail
                                  : values.mail,
                              gsm:
                                values.phone.length == 0
                                  ? userData.gsm
                                  : values.phone,
                              pass:
                                values.password.length == 0
                                  ? userData.pass
                                  : values.password,
                              userImage: values.file
                                ? filePath
                                : userData.uerImage,
                            }),
                          })
                            .then(function (response) {
                              //console.log(JSON.stringify(response.data));
                            })
                            .catch(function (error) {
                              console.log(error);
                            });
                          setLoad(false);
                          swal({
                            title:
                              "Bilgileriniz başarılı bir şeklide güncellendi",
                            text: "Keyifli vakitler dileriz.",
                            icon: "success",
                            button: "Tamam",
                          }).then(() => {
                            navigate(0);
                          });
                        }}
                      >
                        {(formik) => (
                          <Form encType="multipart/form-data">
                            {isLoad ? (
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  className="spinner-border text-warning"
                                  role="status"
                                  style={{
                                    margin: "100px 0",
                                  }}
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="user-profile">
                                  <div className="user-avatar">
                                    <img
                                      src={file ? file : userData.userImage}
                                      alt="Maxwell Admin"
                                    />
                                    <label
                                      htmlFor="x"
                                      style={{
                                        position: "relative",
                                        bottom: 20,
                                        left: 40,
                                        color: "rgb(255, 75, 43)",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <PlusCircleFill size={20} />
                                      <input
                                        type="file"
                                        className="mb-4 mt-2 form-control"
                                        id="x"
                                        style={{ display: "none" }}
                                        name="file"
                                        accept="image/*"
                                        onChange={(e) => {
                                          setFile(
                                            URL.createObjectURL(
                                              e.currentTarget.files[0]
                                            )
                                          );
                                          return formik.setFieldValue(
                                            "file",
                                            e.currentTarget.files[0]
                                          );
                                        }}
                                        multiple
                                      />
                                    </label>
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    ></div>
                                  </div>
                                  <h5 className="user-name">
                                    {userData.fullName}
                                  </h5>
                                  <h6 className="user-email">
                                    {userData.mail}
                                  </h6>
                                </div>

                                <div className="row gutters">
                                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <h6
                                      className="mb-2 text-primary"
                                      style={{
                                        fontSize: 21,
                                        padding: "10px 0",
                                        textAlign: "center",
                                      }}
                                    >
                                      Profil Bilgilerim
                                    </h6>
                                  </div>

                                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    {!fullName ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <a
                                          onClick={() => {
                                            setFullName(true);
                                          }}
                                          style={{
                                            backgroundColor: "#ff4b2b",
                                            padding: 10,
                                            color: "#fff",
                                            borderRadius: 5,
                                            textTransform: "uppercase",
                                            cursor: "pointer",
                                            margin: "10px 5px",
                                          }}
                                        >
                                          <PencilSquare fontSize={20} />
                                        </a>
                                        <label
                                          htmlFor=""
                                          style={{
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            marginRight: 20,
                                            color: "#ff4b2b",
                                          }}
                                        >
                                          {userData.fullName}
                                        </label>
                                      </div>
                                    ) : (
                                      <div
                                        className="form-group"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          className="account-form-control"
                                          name="fullName"
                                          id="fullName"
                                          placeholder="Adınız ve Soyadınız"
                                          value={formik.values.fullName}
                                          onChange={formik.handleChange}
                                          style={{
                                            marginRight: 10,
                                          }}
                                        />
                                        <a
                                          onClick={() => setFullName(false)}
                                          style={{
                                            marginBottom: 12,
                                            backgroundColor: "#ff4b2b",
                                            padding: 10,
                                            color: "#fff",
                                            borderRadius: 5,
                                            textTransform: "uppercase",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <X fontSize={20} />
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    {!mail ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <a
                                          onClick={() => {
                                            setMail(true);
                                          }}
                                          style={{
                                            backgroundColor: "#ff4b2b",
                                            padding: 10,
                                            color: "#fff",
                                            borderRadius: 5,
                                            textTransform: "uppercase",
                                            cursor: "pointer",
                                            margin: "10px 5px",
                                          }}
                                        >
                                          <PencilSquare fontSize={20} />
                                        </a>
                                        <label
                                          htmlFor=""
                                          style={{
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            marginRight: 20,
                                            color: "#ff4b2b",
                                          }}
                                        >
                                          {userData.mail}
                                        </label>
                                      </div>
                                    ) : (
                                      <div
                                        className="form-group"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          type="email"
                                          className="account-form-control"
                                          name="mail"
                                          id="eMail"
                                          placeholder="E-Posta adresiniz"
                                          value={formik.values.mail}
                                          onChange={formik.handleChange}
                                        />
                                        <a
                                          onClick={() => setMail(false)}
                                          style={{
                                            marginBottom: 12,
                                            backgroundColor: "#ff4b2b",
                                            padding: 10,
                                            color: "#fff",
                                            borderRadius: 5,
                                            textTransform: "uppercase",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <X fontSize={20} />
                                        </a>
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    {!phone ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <a
                                          onClick={() => {
                                            setPhone(true);
                                          }}
                                          style={{
                                            backgroundColor: "#ff4b2b",
                                            padding: 10,
                                            color: "#fff",
                                            borderRadius: 5,
                                            textTransform: "uppercase",
                                            cursor: "pointer",
                                            margin: "10px 5px",
                                          }}
                                        >
                                          <PencilSquare fontSize={20} />
                                        </a>
                                        <label
                                          htmlFor=""
                                          style={{
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            marginRight: 20,
                                            color: "#ff4b2b",
                                          }}
                                        >
                                          {userData.gsm}
                                        </label>
                                      </div>
                                    ) : (
                                      <div
                                        className="form-group"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          className="account-form-control"
                                          name="phone"
                                          id="phone"
                                          placeholder="Telefon numaranız"
                                          value={formik.values.phone}
                                          onChange={formik.handleChange}
                                        />
                                        <a
                                          onClick={() => setPhone(false)}
                                          style={{
                                            marginBottom: 12,
                                            backgroundColor: "#ff4b2b",
                                            padding: 10,
                                            color: "#fff",
                                            borderRadius: 5,
                                            textTransform: "uppercase",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <X fontSize={20} />
                                        </a>
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    {!password ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <a
                                          onClick={() => {
                                            setPassword(true);
                                          }}
                                          style={{
                                            backgroundColor: "#ff4b2b",
                                            padding: 10,
                                            color: "#fff",
                                            borderRadius: 5,
                                            textTransform: "uppercase",
                                            cursor: "pointer",
                                            margin: "10px 5px",
                                          }}
                                        >
                                          <PencilSquare fontSize={20} />
                                        </a>

                                        <input
                                          type={isEye ? "text" : "password"}
                                          style={{
                                            background: "none",
                                            border: "none",
                                            outline: "none",
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            color: "#ff4b2b",
                                            width: "60%",
                                          }}
                                          value={userData.pass}
                                          disabled
                                        />

                                        <a
                                          style={{
                                            padding: 0,
                                            color: "#ff4b2b",
                                            textTransform: "uppercase",
                                            cursor: "pointer",
                                            margin: "10px 5px",
                                            fontSize: 25,
                                          }}
                                          onClick={() => {
                                            return isEye
                                              ? setEye(false)
                                              : setEye(true);
                                          }}
                                        >
                                          {isEye ? (
                                            <EyeSlashFill />
                                          ) : (
                                            <EyeFill />
                                          )}
                                        </a>
                                      </div>
                                    ) : (
                                      <div
                                        className="form-group"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          type="password"
                                          className="account-form-control"
                                          name="password"
                                          id="website"
                                          placeholder="yeni şifrenizi buraya giriniz"
                                          value={formik.values.password}
                                          onChange={formik.handleChange}
                                        />
                                        <a
                                          onClick={() => setPassword(false)}
                                          style={{
                                            marginBottom: 12,
                                            backgroundColor: "#ff4b2b",
                                            padding: 10,
                                            color: "#fff",
                                            borderRadius: 5,
                                            textTransform: "uppercase",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <X fontSize={20} />
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="row gutters">
                                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="text-right">
                                      <br />
                                      <button
                                        type="submit"
                                        id="submit"
                                        name="submit"
                                        className="btn btn-primary"
                                      >
                                        Güncelle
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
