import axios from "axios";
import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useAuth } from "../hooks/useAuth";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

export default function ContractUpload(props) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isContract, setContract] = useState(false);
  const [count, setCount] = useState(0);
  const [isLoading, setLoading] = useState(false);

  let companyFileCount;

  async function reset() {
    localStorage.setItem("urls", JSON.stringify([]));
    setContract(false);

    await axios({
      method: "put",
      url: `https://showmarket-backend.herokuapp.com/api/user/${user._id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        companyFile: [],
      }),
    })
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });

    swal({
      title: "Sözleşme Yükleme aracı başarılı bir şekilde sıfırlandı",
      icon: "success",
      button: "Tamam",
    });
  }

  useEffect(() => {
    //.length - 2
    // localStorage.setItem("urls", JSON.stringify([])); //-> clear stroage
    if (!localStorage.getItem("urls")) {
      localStorage.setItem("urls", JSON.stringify([]));
    }

    axios({
      method: "get",
      url: `https://showmarket-backend.herokuapp.com/api/user/get/${user._id}`,
      headers: {},
    })
      .then(function (response) {
        if (response.data.data.companyFile.length == 2) {
          setContract(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div
      style={
        props.windowSize > 425
          ? {
              width: 400,
              float: "left",
            }
          : null
      }
    >
      <div
        style={{
          marginBottom: 10,
        }}
      >
        <a
          href="https://drive.google.com/file/d/1YbZC5ds5mvsnQu3SeVy2tnpi8HV-7lUm/view"
          download
          style={{
            color: "#ff4b2b",
            textDecoration: "none",
          }}
          target="_blank"
        >
          Mesafeli satış sözleşmesini indir
        </a>
        <br />

        <a
          href="https://drive.google.com/file/d/1lQ0gQftlX5ZaYQ7BBW6MXIcZOdT1HFRO/view"
          download
          style={{
            color: "#ff4b2b",
            textDecoration: "none",
          }}
          target="_blank"
        >
          Kullanıcı sözleşmesini indir
        </a>
      </div>
      <div style={{ fontWeight: 600, fontStyle: "normal", margin: "25px 0" }}>
        {isContract ? (
          <p>
            ! Yanlış, eksik veya hatalı bilgili dosya yüklediyseniz lütfen
            işlemleri tekrarlayınız.
          </p>
        ) : (
          <p>
            ! Sözleşmeleri indiridikten sonra uygun tükenmez mavi kalem
            kullanarak gerekli yerleri doldurup sözleşmeleri Taratıp yüklemeniz
            gerekmektedir.
          </p>
        )}
      </div>

      <Formik
        initialValues={{ file: "" }}
        onSubmit={async (values) => {
          let filePath;

          const formData = new FormData();
          for (let value in values) {
            formData.append(value, values[value]);
          }

          setLoading(true);
          if (companyFileCount != 2) {
            await axios
              .post("https://showmarket-backend.herokuapp.com/upload", formData)
              .then((res) => {
                console.log(res.data.file);
                filePath = res.data.file;

                if (res.data.success) {
                  setCount((count) => count + 1);
                }

                if (
                  JSON.parse(localStorage.getItem("urls")).length >= 0 &&
                  JSON.parse(localStorage.getItem("urls")).length <= 2
                ) {
                  if (!localStorage.getItem("urls")) {
                    localStorage.setItem("urls", JSON.stringify([]));
                  } else {
                    var items = [];
                    items = JSON.parse(localStorage.getItem("urls"));
                    items.push(filePath);
                    localStorage.setItem("urls", JSON.stringify(items));
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          setLoading(false);

          if (
            JSON.parse(localStorage.getItem("urls")).length == 2 &&
            companyFileCount != 2
          ) {
            await axios({
              method: "put",
              url: `https://showmarket-backend.herokuapp.com/api/user/${user._id}`,
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify({
                companyFile: localStorage.getItem("urls"),
              }),
            })
              .then(function (response) {
                console.log(JSON.stringify(response.data));
                localStorage.removeItem("urls");

                swal({
                  title:
                    "Sözleşme belgeleriniz başarılı bir şekilde yüklendi teşekkür ederiz.",
                  icon: "success",
                  button: "Tamam",
                }).then(function () {
                  navigate(0);
                });
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        }}
      >
        {(formik) => (
          <Form encType="multipart/form-data">
            {isLoading ? (
              <div className="spinner-border text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <>
                <div className="w-100 text-center my-3">
                  <label
                    htmlFor="x"
                    style={{
                      fontSize: 20,
                      color: "#ff4b2b",
                    }}
                  >
                    <strong>
                      {!isContract ? (
                        <>
                          {count == 0
                            ? "Kullanıcı sözleşmesini yükleyin"
                            : null}
                          {count == 1 ? "Satış sözleşmesini yükleyin" : null}
                        </>
                      ) : null}
                    </strong>
                  </label>
                </div>
                {!isContract ? (
                  <>
                    <input
                      className="form-control"
                      type="file"
                      id="formFile"
                      name="file"
                      htmlFor="x"
                      onChange={(e) =>
                        formik.setFieldValue("file", e.currentTarget.files[0])
                      }
                      multiple
                      required
                      disabled={isContract ? "disabled" : null}
                    />
                    <div
                      style={
                        props.windowSize > 425
                          ? {
                              display: "flex",
                              justifyContent: "right",
                              marginTop: "5%",
                            }
                          : {
                              display: "flex",
                              justifyContent: "left",
                              margin: "7% 0",
                            }
                      }
                    >
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isContract ? "disabled" : null}
                        style={{
                          backgroundColor: "#ff4b2b",
                          padding: "0.6rem 2.4rem",
                          textTransform: "uppercase",
                          color: "#fff",
                          borderRadius: "2rem",
                          fontWeight: "700",
                          border: "1px solid #ff4b2b",
                          transition: "all 200ms linear",
                          marginBottom: 5,
                        }}
                      >
                        Gönder
                      </button>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      marginTop: "5%",
                    }}
                  >
                    <button
                      className="btn btn-primary"
                      onClick={reset}
                      style={{
                        backgroundColor: "#ff4b2b",
                        padding: "0.6rem 2.4rem",
                        textTransform: "uppercase",
                        color: "#fff",
                        borderRadius: "2rem",
                        fontWeight: "700",
                        border: "1px solid #ff4b2b",
                        transition: "all 200ms linear",
                        marginBottom: 5,
                      }}
                    >
                      sıfırla
                    </button>
                  </div>
                )}
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
