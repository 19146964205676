class WageCalculation {
  calcCrow(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = ((lat2 - lat1) * Math.PI) / 180; //toRad(lat2 - lat1);
    var dLon = ((lon2 - lon1) * Math.PI) / 180; //toRad(lon2 - lon1);
    var lat1 = (lat1 * Math.PI) / 180; // toRad(lat1);
    var lat2 = (lat2 * Math.PI) / 180; //toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  PriceCalc(price, mil) {
    return Math.floor(price * mil);
  }
}

export default new WageCalculation();
