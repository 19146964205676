export default function HakkimizdaBlogPage() {
  return (
    <div className="hakkimizda-blog-content">
      <div className="col hakkimizda-card">
        <h5 className="body-title-desc">SHOW MARKET</h5>
        <p className="hakkimizdaParagraf">
          Turizm - Eğlence sektöründe 2000'li yıllarda başlayan dış gösterilerin
          tesislerce kullanımı hızla artmış, kaliteli dans ve müzik gruplarının
          yanı sıra ses-ışık-görüntü sistemleri, podyum, trass, şişme eğlence
          aktiviteleri, dekor ve masa sandalye giydirme hizmetleri gibi birçok
          kulvarda tesis dışı firmalar aracılığı ile otellerin kullanımına
          sunulmuştur. Belli bir süre sonra bu hizmetler başlı başına bir sektör
          haline gelmiş ve büyük bir rant pazarı oluşturmuştur. Doğru ürünlere
          doğru fiyatlarda ulaşmak, sistemin oluşturduğu aracı ve bazı
          çevrelerin yönlendirmesiyle yol almaktadır.
        </p>
        <p className="hakkimizdaParagraf">
          Bu sebeple tesislerin karar mekanizmalarını hizmet alımlarında doğru
          yönlendirebilmek ve gerçek bir pazar oluşturabilmek adına Show Market
          uygulamamızı geliştirdik. 2019 yılında çıktığımız bu yolda,
          Oluşturulan genç yetenekler ile 3 yıl boyunca çalışarak tüm satır
          kodları yerli ve milli imkanlar ile oluşturulmuş uygulamamız 2022
          yılında hizmetinize sunulmuştur.
        </p>
        <p className="hakkimizdaParagraf">
          1.0 versiyonu ile tüm gösteri ve eğlence sektörünün kullanımına
          sunulan Show Market mobil uygulaması ilk etapta kullanıcı ve
          tedarikçileri lokasyonel bazda bir araya getiren kolay kullanımlı,
          güvenilir bir program olarak hizmet vermeyi amaçlamaktadır.
        </p>
      </div>

      <div className="col hakkimizda-card eglenceninAdresi">
        <h5 className="body-title-desc">EĞLENCENİN ADRESİ</h5>

        <p className="hakkimizdaParagraf">
          Gelecek vizyonda katılımcıların da dahil olarak yorum ve paylaşım
          yapabilecekleri hatta bilet bulabilecekleri bir platform olarak hizmet
          alanlarını yurt çapına ve tüm kitlelere sunmayı amaçlamaktadır.
        </p>

        <p className="hakkimizdaParagraf">
          Bir sonraki versiyonunda global çapta hizmet vererek Metaverse (sanal
          evren)’de de hizmete sunup etkinliklere katılım ve satış hizmeti
          vermek. Acentaları da dahil ederek 'barter' ticareti sektöre
          kazandırmak hatta sanal paralarla alışverişe imkân vermeyi
          amaçlamaktadır. Show Market olarak daima yenilikçi ve güncel bir
          uygulama olmayı ve her daim güvenilir hizmeti hedeflemekteyiz.
        </p>

        <p className="hakkimizdaParagraf">
          Uygulamanın sağlayacağı kazanç ve imkanların kazan-kazan politikası
          ile tüm sektör çarklarına ivme vererek kolaylık getireceğine
          inandığımız Show Market sadece turizm alanında değil kurumsal ve
          bireysel her türlü ihtiyaca cevap verebilecek alt yapıda
          kurgulanmıştır.
        </p>

        <p className="hakkimizdaParagraf">
          Misyon, vizyon bakış açılarımıza fütürist kurguyu da ekleyerek tüm
          kullanıcıların beğenerek işlem yapabilecekleri sistem, öncelikle güven
          ve kolaylık üzerine inşa edilmiştir. İnsanlığın mutluluğunda faydalı
          olmayı ilke edinen çalışmalarımız ile siz değerli kullanıcılarımızla
          birlikte yol almanın gururunu paylaşmaktayız.
        </p>
      </div>
    </div>
  );
}
