import BlogCard from "../components/BlogCard";
import BlogCourseal from "../components/BlogCourseal";
export default function Blog() {
  return (
    <>
      <BlogCourseal />
      <div className="container">
        <BlogCard />
      </div>
    </>
  );
}
