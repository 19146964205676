import React, { useEffect, useState } from "react";

import L from "leaflet";
import {
  useMap,
  TileLayer,
  FeatureGroup,
  MapContainer,
  Circle,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import drawCoordinates from "../helpers/DrawCoordinate";
import axios from "axios";
import swal from "sweetalert";
import { useAuth } from "../hooks/useAuth";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});

export default function ServiceArea() {
  const { user, logout } = useAuth();
  const [center, setCenter] = useState({
    lat:
      user.servicesRange.length != 0
        ? user.servicesRange[0].latitude
        : 41.048038,
    lng:
      user.servicesRange.length != 0
        ? user.servicesRange[0].longitude
        : 29.022223,
  });
  const [drawCricle, setDrawCricle] = useState({});

  const ZOOM_LEVEL = 12;
  const mapRef = useRef();

  useEffect(() => {
    console.log(user.servicesRange.length != 0);
    if (drawCricle) {
      drawCoordinates.setDrawStorageCoordinate(drawCricle);
    }
  }, []);

  const saveMapArea = async () => {
    if (drawCricle) {
      drawCoordinates.setDrawStorageCoordinate(drawCricle);
      axios({
        method: "put",
        url: `https://showmarket-backend.herokuapp.com/api/user/${user._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          servicesRange: [
            {
              latitude: drawCoordinates.getDrawStorage().latitude,
              longitude: drawCoordinates.getDrawStorage().longitude,
              range: drawCoordinates.getDrawStorage().range,
            },
          ],
        }),
      })
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          swal({
            title: "Hizmet verdiğiniz alan başarılı bir şekilde kaydedildi",
            icon: "success",
            button: "Tamam",
          }).then(() => {
            logout();
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      swal({
        title: "Lütfen hizmet alanını seçin",
        icon: "warning",
        button: "Tamam",
      });
    }
  };

  return (
    <div className="card w-100 px-4 d-flex align-items-center">
      <h1 className="text-center py-4">
        Hizmet vermek istediğiniz alanı çizin
      </h1>
      <MapContainer
        center={center}
        zoom={ZOOM_LEVEL}
        ref={mapRef}
        style={{
          width: "1000px",
          height: "calc(100vh - 20rem)",
          position: "relative",
        }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        <Circle
          center={[
            user.servicesRange.length != 0
              ? user.servicesRange[0].latitude
              : 41.048038,
            user.servicesRange.length != 0
              ? user.servicesRange[0].longitude
              : 29.022223,
          ]}
          radius={
            user.servicesRange.length != 0 ? user.servicesRange[0].range : null
          }
        />
        <FeatureGroup>
          <EditControl
            position="topright"
            onCreated={(e) => {
              return setDrawCricle({
                data: e.target._renderer._center,
                radius: e.layer.options.radius,
              });
            }}
            edit={{
              edit: false,
            }}
            draw={{
              polygon: false,
              rectangle: false,
              circle: true,
              circlemarker: false,
              marker: false,
              polyline: false,
            }}
          />
        </FeatureGroup>
      </MapContainer>
      <button
        className="btn btn-primary my-4"
        style={{
          width: "200px",
        }}
        onClick={saveMapArea}
      >
        Kaydet
      </button>
    </div>
  );
}
