import "../components/assets/login.css";
import LogoShowMarket from "../components/assets/img/logo.png";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";

import LoginWall from "../components/assets/img/loginregister.jpeg";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

export default function Register() {
  const [register, setRegister] = useState("kurumsal");
  const [messages, setMessages] = useState();
  const [coordinates, setCoordinates] = useState({
    latitude: Number,
    longitude: Number,
  });

  const navigate = useNavigate();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const RegisterSchema = Yup.object().shape({
    fullName: Yup.string().required("Zorunlu alan *"),
    mail: Yup.string().email("Geçersiz format").required("Zorunlu alan *"),
    gsm: Yup.string()
      .matches(phoneRegExp, "Geçersiz telefon numarası")
      .required("Zorunlu alan *"),
    pass: Yup.string().required("Zorunlu alan *"),
    taxNum:
      register == "kurumsal" ? Yup.number().required("Zorunlu alan *") : null,
    company:
      register == "kurumsal" ? Yup.string().required("Zorunlu alan *") : null,
    // companyRank:
    //   register == "kurumsal" ? Yup.string().required("Zorunlu alan *") : null,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      console.log(position);
      setCoordinates({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
    
  }, []);

  return (
    <div className="login-container">
      <div className="row container-main-style">
        <div
          className="login-wrapper-register col-md-6"
          id="standartUser"
          style={{
            marginTop: "1%",
          }}
        >
          <div className="heading">
            <img src={LogoShowMarket} width="25%" alt="" />
            <h1>Kayıt Ol</h1>
            <p className="account-heading">
              <a href="/login" className="btn btn-forget">
                Hesabın zaten varsa giriş yap
              </a>
            </p>
          </div>
          <Formik
            initialValues={{
              fullName: "",
              mail: "",
              gsm: "",
              pass: "",
              company: "",
              taxNum: "",
              companyRank: "",
            }}
            validationSchema={RegisterSchema}
            onSubmit={async (dt) => {
              await axios({
                method: "POST",
                url: "https://showmarket-backend.herokuapp.com/api/user/register",
                headers: {
                  "Content-Type": "application/json",
                },
                data: JSON.stringify({
                  coordinates: [coordinates],
                  mail: dt.mail,
                  fullName: dt.fullName,
                  gsm: dt.gsm,
                  pass: dt.pass,

                  taxNum: register == "kurumsal" ? dt.taxNum : "",
                  companyRank:
                    register == "kurumsal"
                      ? dt.companyRank.length == 0
                        ? "Şahıs Şirketi"
                        : dt.companyRank
                      : "",
                  company: register == "kurumsal" ? dt.company : "",

                  isActive: false,
                  isAdmin: false,
                  isCompany: register == "kurumsal" ? true : false,
                  userImage:
                    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
                }),
              })
                .then(function (response) {
                  if (response.data.message == "Created") {
                    setMessages("kaydınız oluşturuldu");
                    swal({
                      title:
                        "Kaydınızı başarılı bir şekilde aldık bizi tercih ettiğiniz için teşekkür ederiz",
                      text: "Tamam butonuna bastığınızda login sayfasına yönlendirileceksiniz",
                      icon: "success",
                      button: "Tamam",
                    }).then(() => {
                      navigate("/login");
                    });
                  }
                })
                .catch(function (error) {
                  if (
                    error.response.data.message ==
                    "Böyle bir kullanıcı zaten kayıtlı"
                  ) {
                    swal({
                      title:
                        "Böyle bir kullanıcı sistemimizde kayıtlı lütfen giriş yapın yada başka bir kullanıcı oluşturmayı deneyin",
                      icon: "warning",
                      button: "Tamam",
                    });
                  }
                });
            }}
          >
            {(formik) => (
              <Form className="form-group">
                <a
                  className="btn btn-primary"
                  onClick={() => {
                    if (register === "kurumsal") {
                      setRegister("bireysel");
                    }
                  }}
                >
                  Bireysel Kayıt
                </a>
                <a
                  className="btn btn-primary"
                  onClick={() => {
                    if (register === "bireysel") {
                      setRegister("kurumsal");
                    }
                  }}
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Kurumsal Kayıt
                </a>
                <br />
                {messages ? <div>{messages}</div> : null}
                {register === "kurumsal" ? (
                  <>
                    <input
                      type="text"
                      placeholder="İş yeri ünvanı"
                      className="isYeriUnvani"
                      name="company"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.company && formik.touched.company ? (
                      <div>{formik.errors.company}</div>
                    ) : null}
                    <input
                      type="text"
                      placeholder="Vergi Numarası"
                      className="vergiNo"
                      name="taxNum"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.taxNum && formik.touched.taxNum ? (
                      <div>{formik.errors.taxNum}</div>
                    ) : null}
                    <select
                      name="companyRank"
                      className="company-type"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    >
                      <option value="Şahıs Şirketi">Şahıs Şirketi</option>
                      <option value="Anonim Şirket">Anonim Şirket</option>
                      <option value="Limited Şirket">Limited Şirket</option>
                      <option value="Kollektif Şirket">Kollektif Şirket</option>
                      <option value="Komandit Şirket">Komandit Şirket</option>
                      <option value="Kooperatif Şirket">
                        Kooperatif Şirket
                      </option>
                    </select>
                    {formik.errors.companyRank && formik.touched.companyRank ? (
                      <div>{formik.errors.companyRank}</div>
                    ) : null}
                  </>
                ) : null}
                <input
                  type="text"
                  placeholder="Adınız ve soyadınız"
                  className="fullname"
                  name="fullName"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.fullName && formik.touched.fullName ? (
                  <div>{formik.errors.fullName}</div>
                ) : null}
                <input
                  type="text"
                  placeholder="email"
                  className="email"
                  name="mail"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.mail && formik.touched.mail ? (
                  <div>{formik.errors.mail}</div>
                ) : null}
                <input
                  type="text"
                  placeholder="GSM Numaranız"
                  className="telno"
                  name="gsm"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.gsm && formik.touched.gsm ? (
                  <div>{formik.errors.gsm}</div>
                ) : null}
                <input
                  type="password"
                  placeholder="password"
                  className="passwd"
                  name="pass"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.pass && formik.touched.pass ? (
                  <div>{formik.errors.pass}</div>
                ) : null}
                {register === "bireysel" ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      margin: "10px 0",
                    }}
                  >
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <a
                          href="https://drive.google.com/file/d/14rYcDHDr-oXRbWVHu4tu_HrR8Rq1Xgiv/view"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#FB3C00",
                            fontWeight: "bold",
                          }}
                        >
                          Kullanıcı sözleşmesini kabul ediyorum
                        </a>
                      </label>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      marginBottom: 5,
                    }}
                  >
                    <div
                      className="form-check"
                      style={{
                        margin: "5px 0",
                      }}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <a
                          href="https://drive.google.com/file/d/14rYcDHDr-oXRbWVHu4tu_HrR8Rq1Xgiv/view"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#FB3C00",
                            fontWeight: "bold",
                          }}
                        >
                          Kullanıcı sözleşmesini kabul ediyorum
                        </a>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <a
                          href="https://drive.google.com/file/d/1YbZC5ds5mvsnQu3SeVy2tnpi8HV-7lUm/view"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#FB3C00",
                            fontWeight: "bold",
                          }}
                        >
                          Mesafeli satış sözleşmesini kabul ediyorum
                        </a>
                      </label>
                    </div>
                  </div>
                )}
                <button type="submit" className="btn btn-primary">
                  Kayıt Ol
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <div className="col-md-6 login-img-wrapper">
          <img src={LoginWall} alt="not load" className="login-img" />
        </div>
      </div>
    </div>
  );
}
