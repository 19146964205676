import axios from "axios";
import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { BookmarkPlus, PenFill, TrashFill } from "react-bootstrap-icons";
import swal from "sweetalert";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
export default function AdressTab() {
  const [adress, setAdress] = useState([]);
  const [updateAdress, setUpdateAdress] = useState(false);
  const [getIdAdressData, setIdAdressData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const user = useAuth();
  const getData = async () => {
    axios({
      method: "GET",
      url: `https://showmarket-backend.herokuapp.com/api/adress/get-by-user/${user.user._id}`,
      headers: {},
    })
      .then((res) => {
        setAdress(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const deleteAdress = async (id) => {
    await axios({
      method: "delete",
      url: `https://showmarket-backend.herokuapp.com/api/adress/${id}`,
      headers: {},
    })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getAdressId = async (id) => {
    await axios({
      method: "get",
      url: `https://showmarket-backend.herokuapp.com/api/adress/${id}`,
      headers: {},
    })
      .then(function (response) {
        console.log(response.data.data);
        setIdAdressData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  return (
    <div className="card w-100">
      <div className="card-body-custom">
        <div className="d-flex justify-content-center w-100 my-4">
          <h3 className="text-primary">ADRES BİLGİLERİM</h3>
        </div>
        <div className="add-adress-buton-container">
          <button
            type="button"
            class="add-adress-button-style d-flex align-items-center "
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => {
              setUpdateAdress(false);
            }}
          >
            <strong>
              <BookmarkPlus /> Yeni Adres
            </strong>
          </button>
        </div>

        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {updateAdress ? "Adres Güncelle" : "Adres Ekle"}
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    id: "",
                    country: "",
                    city: "",
                    distinct: "",
                    detail: "",
                  }}
                  onSubmit={async (values) => {
                    if (updateAdress) {
                      await axios({
                        method: "put",
                        url: `https://showmarket-backend.herokuapp.com/api/adress/${getIdAdressData._id}`,
                        headers: {
                          "Content-Type": "application/json",
                        },
                        data: JSON.stringify({
                          country:
                            values.country === ""
                              ? getIdAdressData.country
                              : values.country,
                          city:
                            values.city === ""
                              ? getIdAdressData.city
                              : values.city,
                          distinct:
                            values.distinct === ""
                              ? getIdAdressData.distinct
                              : values.distinct,
                          detail:
                            values.detail === ""
                              ? getIdAdressData.detail
                              : values.detail,
                        }),
                      })
                        .then(function (response) {
                          console.log(JSON.stringify(response.data.data));
                        })
                        .catch(function (error) {
                          console.log(error);
                        });
                      swal({
                        title:
                          "Adres bilginiz başarılı bir şekilde güncellendi",
                        text: "Keyifli vakitler dileriz.",
                        icon: "success",
                        button: "Tamam",
                      }).then(function () {
                        navigate(0);
                      });
                    } else {
                      await axios({
                        method: "post",
                        url: "https://showmarket-backend.herokuapp.com/api/adress/",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        data: JSON.stringify({
                          userID: user.user._id,
                          city: values.city,
                          distinct: values.distinct,
                          country: values.country,
                          detail: values.detail,
                        }),
                      })
                        .then(function (response) {
                          console.log(JSON.stringify(response.data.data));
                        })
                        .catch(function (error) {
                          console.log(error);
                        });
                      swal({
                        title: "Adres bilginiz başarılı bir şekilde eklendi",
                        text: "Keyifli vakitler dileriz.",
                        icon: "success",
                        button: "Tamam",
                      }).then(function () {
                        navigate(0);
                      });
                    }
                  }}
                >
                  {(formik) => (
                    <Form>
                      <div class="input-group mb-3">
                        <span
                          class="input-group-text"
                          id="inputGroup-sizing-default"
                        >
                          {updateAdress ? getIdAdressData.country : "Ülke"}
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          aria-label="Sizing example input"
                          aria-describedby="inputGroup-sizing-default"
                          name="country"
                          value={formik.values.country}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div class="input-group mb-3">
                        <span
                          class="input-group-text"
                          id="inputGroup-sizing-default"
                        >
                          {updateAdress ? getIdAdressData.city : "Şehir"}
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          aria-label="Sizing example input"
                          aria-describedby="inputGroup-sizing-default"
                          name="city"
                          value={formik.values.city}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div class="input-group mb-3">
                        <span
                          class="input-group-text"
                          id="inputGroup-sizing-default"
                        >
                          {updateAdress ? getIdAdressData.distinct : "İlçe"}
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          aria-label="Sizing example input"
                          aria-describedby="inputGroup-sizing-default"
                          name="distinct"
                          value={formik.values.distinct}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div class="input-group">
                        <span class="input-group-text">Adres</span>
                        <textarea
                          class="form-control"
                          aria-label="With textarea"
                          name="detail"
                          value={formik.values.detail}
                          onChange={formik.handleChange}
                          defaultValue={
                            updateAdress ? getIdAdressData.detail : ""
                          }
                        ></textarea>
                      </div>
                      <button type="submit" class="btn btn-primary mt-4">
                        {updateAdress ? "Güncelle" : "Ekle"}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="adress-wrapper-content">
          {adress.length != 0 ? (
            adress.map((data, index) => {
              console.log(index);
              return (
                <div class="card" style={{ width: "18rem" }} key={data._id}>
                  <div class="card-body">
                    <h5 class="card-title">{data.country}</h5>
                    <h6 class="card-subtitle mb-2 text-muted">
                      {data.city} / {data.distinct}
                    </h6>
                    <p class="card-text">{data.detail}</p>
                    <div className="d-flex justify-content-center">
                      <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="card-button-custom d-flex align-items-center"
                        onClick={() => {
                          getAdressId(data._id);
                          setUpdateAdress(true);
                        }}
                      >
                        <PenFill /> Düzenle
                      </a>
                      <a
                        onClick={() => {
                          swal({
                            title: "Adres kaydınzı silmek üzeresiniz!",
                            text: "Silmeden önce adres kaydınızın geri getirilemez olduğunu belirtmek isteriz",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then((willDelete) => {
                            if (willDelete) {
                              deleteAdress(data._id);
                              swal(
                                "Adres kaydınız başarılı bir şekilde silindi",
                                {
                                  icon: "success",
                                }
                              ).then(() => {
                                navigate(0);
                              });
                            }
                          });
                        }}
                        class="card-button-custom d-flex align-items-center"
                      >
                        <TrashFill /> Sil
                      </a>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: "300px" }}
            >
              <div class="alert alert-danger px-4 py-4" role="alert">
                <h4>
                  <strong>Adres kaydınız bulunmamaktadır</strong>
                </h4>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
